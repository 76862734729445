var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "pgTitle" }, [_vm._v("My Community")]),
    _c("div", { staticClass: "community" }, [
      _c(
        "div",
        { staticClass: "groupItems" },
        [
          _c("BrandStoryListFollows", {
            staticClass: "sectionFollowedBrandStories",
          }),
          _c(
            "div",
            { staticClass: "sectionFollowUsers" },
            [_c("UserFollowListTop"), _c("UserFollowListToFollow")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }